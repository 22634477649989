import { useMemo, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { CssBaseline } from "@mui/material"
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles"
import breakpoints from "./breakpoints"
import palette from "./palette"
import typography from "./typography"

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props): JSX.Element {
  const { t } = useTranslation("dashboard", { keyPrefix: "mainLayout" })

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette.light,
      typography: { ...typography, fontFamily: t("font") },
      breakpoints,
      shape: { borderRadius: 8 },
      spacing: 4,
    }),
    [t("font")]
  )

  const theme = createTheme(themeOptions)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}
