import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { styled, Box, Tooltip } from "@mui/material"
import { Icon } from "@iconify/react"
import { currentImageIdSelector } from "../redux/slices/user"
import { PATH_DASHBOARD } from "../routes/paths"

interface ListItemProps {
  selected: boolean
  disabled?: boolean
}

const Wrapper = styled(Box)(({ theme }) => ({
  minWidth: 108,
  height: "100%",
  background: "rgba(12, 47, 82, 0.50)",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflowY: "auto",
}))

const ListItem = styled(Box)<ListItemProps>(
  ({ theme, selected, disabled }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: !disabled ? "pointer" : "initial",
    color: disabled
      ? theme.palette.grey[700]
      : selected
      ? "#05c9df"
      : theme.palette.grey[400],
    marginBottom: theme.spacing(10),
    justifyContent: "center",
    padding: theme.spacing(3),
    textAlign: "center",
    borderRadius: theme.spacing(3),
    userSelect: "none",

    "&:hover": {
      color: !disabled ? "##0580C0" : "intial",
    },
  })
)

const SidebarIcon = styled(Icon)(() => ({
  fontSize: 50,
}))

function Sidebar(): JSX.Element {
  const navigate = useNavigate()

  const location = useLocation()

  const isSelectedTab = (route: string): boolean => {
    return location.pathname === route
  }

  const currentImageId = useSelector(currentImageIdSelector)

  const { t } = useTranslation("dashboard", { keyPrefix: "sidebar" })

  return (
    <Wrapper>
      <Tooltip title={t("selectImage")}>
        <ListItem
          selected={isSelectedTab(PATH_DASHBOARD.selectImage)}
          onClick={() => navigate(PATH_DASHBOARD.selectImage)}
        >
          <SidebarIcon icon="ic:round-image" />
        </ListItem>
      </Tooltip>
      <Tooltip title={t("imageImprovement")}>
        <ListItem
          disabled={!(currentImageId !== undefined && currentImageId !== null)}
          selected={isSelectedTab(PATH_DASHBOARD.improvement)}
          onClick={() => {
            if (currentImageId !== undefined && currentImageId !== null)
              navigate(PATH_DASHBOARD.improvement)
          }}
        >
          <SidebarIcon icon="dashicons:image-flip-horizontal" />
        </ListItem>
      </Tooltip>
      <Tooltip title={t("boundaries")}>
        <ListItem
          disabled={!(currentImageId !== undefined && currentImageId !== null)}
          selected={isSelectedTab(PATH_DASHBOARD.boundaries)}
          onClick={() => {
            if (currentImageId !== undefined && currentImageId !== null)
              navigate(PATH_DASHBOARD.boundaries)
          }}
        >
          <SidebarIcon icon="tabler:dental" />
        </ListItem>
      </Tooltip>
      <Tooltip title={t("treatments")}>
        <ListItem
          sx={{
            WebkitTextStrokeWidth: "100px",
            WebkitTextStrokeColor: "red",
          }}
          disabled={!(currentImageId !== undefined && currentImageId !== null)}
          selected={isSelectedTab(PATH_DASHBOARD.treatments)}
          onClick={() => {
            if (currentImageId !== undefined && currentImageId !== null)
              navigate(PATH_DASHBOARD.treatments)
          }}
        >
          <SidebarIcon icon="healthicons:odontology-implant" />
        </ListItem>
      </Tooltip>
      <Tooltip title={t("analysis")}>
        <ListItem
          disabled={!(currentImageId !== undefined && currentImageId !== null)}
          selected={isSelectedTab(PATH_DASHBOARD.analysis)}
          onClick={() => {
            if (currentImageId !== undefined && currentImageId !== null)
              navigate(PATH_DASHBOARD.analysis)
          }}
        >
          <SidebarIcon icon="solar:bone-crack-bold" />
        </ListItem>
      </Tooltip>
    </Wrapper>
  )
}

export default Sidebar
