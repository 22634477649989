/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ReactNode,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import Cookies from "js-cookie"
import {
  cookiesExpires,
  cookiesKey,
  defaultSettings,
} from "../constants/config"
import useLocales, { LangValues } from "../hooks/useLocales"
import {
  SettingsContextProps,
  SettingsValueProps,
  ThemeDirection,
} from "../types/contexts"

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => {},
  setDirection: () => {},
}

const SettingsContext = createContext(initialState)

type SettingsProviderProps = {
  children: ReactNode
}

function SettingsProvider({ children }: SettingsProviderProps): JSX.Element {
  const direction = Cookies.get("themeDirection")

  const [settings, setSettings] = useSettingCookies({
    ...defaultSettings,
    themeDirection: direction === "ltr" ? "ltr" : "rtl",
  })

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === "light" ? "dark" : "light",
    })
  }

  const setDirection = (dir: ThemeDirection) => {
    setSettings({
      ...settings,
      themeDirection: dir as ThemeDirection,
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onToggleMode,
        setDirection,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsProvider, SettingsContext }

function useSettingCookies(
  defaultSettings: SettingsValueProps
): [SettingsValueProps, Dispatch<SetStateAction<SettingsValueProps>>] {
  const [settings, setSettings] = useState<SettingsValueProps>(defaultSettings)

  const onChangeSetting = () => {
    Cookies.set(cookiesKey.themeMode, settings.themeMode, {
      expires: cookiesExpires,
    })

    Cookies.set(cookiesKey.themeDirection, settings.themeDirection, {
      expires: cookiesExpires,
    })
  }

  const { onChangeLang } = useLocales()

  useEffect(() => {
    const lang = Cookies.get("language")

    if (!lang) {
      Cookies.set("language", LangValues.Fa)
      onChangeLang(LangValues.Fa)
    } else
      lang === LangValues.En
        ? onChangeLang(LangValues.En)
        : onChangeLang(LangValues.Fa)
  }, [])

  useEffect(() => {
    onChangeSetting()
  }, [settings])

  return [settings, setSettings]
}
