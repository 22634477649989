import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { Box, Button, Stack, styled, Tooltip, Typography } from "@mui/material"
import { Icon } from "@iconify/react"
import { userSelector } from "../redux/slices/user"
import { PATH_DASHBOARD } from "../routes/paths"
import Sidebar from "./Sidebar"

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  height: "100%",
  position: "relative",
  paddingTop: theme.spacing(24),
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: 'url("/static/images/mainBackground.png")',
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundColor: theme.palette.grey[900],
}))

const DentalLogo = styled("img")(() => ({
  position: "absolute",
  top: 28,
  left: 60,
  height: 80,
  cursor: "pointer",
}))

const HeaderLine = styled("hr")(({ theme }) => ({
  backgroundColor: "#B7ECEA",
  height: 2,
  marginBottom: theme.spacing(2),
  border: "unset",
}))

const InfoBar = styled(Stack)(({ theme }) => ({
  height: 36,
  width: "100%",
  position: "relative",
  background:
    "linear-gradient(to right, #05C9DF 0%, #0580C0 26%, #05C9DF 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #05C9DF 0%, #0580C0 26%, #05C9DF 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #05C9DF 0%, #0580C0 26%, #05C9DF 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, #05C9DF 0%, #0580C0 26%, #05C9DF 50%) top right / 50% 50% no-repeat",
  backgroundSize: "100% 36px",
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(4),
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
}))

const IconButton = styled(Button)(({ theme }) => ({
  minWidth: "0px",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  color: theme.palette.grey[900],
}))

function MainLayout(): JSX.Element {
  const navigate = useNavigate()

  const location = useLocation()

  const userInfo = useSelector(userSelector)

  const isSplashPage = location.pathname === "/splash"

  const logout = () => {
    localStorage.removeItem("dental-access-token")
    localStorage.removeItem("dental-refresh-token")
    navigate(PATH_DASHBOARD.login)
  }

  const { t } = useTranslation("dashboard", { keyPrefix: "mainLayout" })

  return (
    <Wrapper>
      <Stack direction="row" mb={2}>
        <DentalLogo
          src="/static/images/header-logo.png"
          alt="dental"
          onClick={() => navigate(PATH_DASHBOARD.splash)}
        />
        <HeaderLine />
      </Stack>
      <InfoBar spacing={3} direction="row">
        {!!userInfo && !isSplashPage && (
          <>
            <Typography variant="subtitle2">
              {`${userInfo.file_name} :نام فایل تصویر`}
            </Typography>
            <Typography variant="subtitle2">
              {`${userInfo.image_date} :تاریخ ایجاد رادیوگرافی`}
            </Typography>
            <Typography variant="subtitle2">
              {`${userInfo.code_melli} :شماره ملی`}
            </Typography>
            <Typography variant="subtitle2">
              {`${userInfo.patient_birth} :تاریخ تولد`}
            </Typography>
            <Typography variant="subtitle2">
              {`نام بیمار: ${userInfo.patient_name} `}
            </Typography>
          </>
        )}
        <Tooltip title={t("logout")}>
          <IconButton onClick={() => logout()} sx={{ left: "10px" }}>
            <Icon icon="mingcute:exit-line" fontSize={25} />
          </IconButton>
        </Tooltip>
      </InfoBar>
      <Stack spacing={3} direction="row" height="calc(100% - 85px)">
        <Sidebar />
        <Box
          sx={{
            width: "calc(100% - 124px)",
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Wrapper>
  )
}

export default MainLayout
