import { SettingsValueProps } from "../types/contexts"

export const cookiesExpires = 3

export const cookiesKey = {
  themeMode: "themeMode",
  themeDirection: "themeDirection",
}

export const defaultSettings: SettingsValueProps = {
  themeMode: "dark",
  themeDirection: "ltr",
}
