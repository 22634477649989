const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1400,
    xl: 1400,
  },
}

export default breakpoints
