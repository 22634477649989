import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from ".."
import InitialState, { UserInfoType } from "../../types/redux/user"

const initialState: InitialState = {
  userInfo: null,
  currentImageId: null,
}

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: InitialState, action: PayloadAction<UserInfoType>) => {
      state.userInfo = action.payload
    },
    setCurrentImageId: (state: InitialState, action: PayloadAction<number>) => {
      state.currentImageId = action.payload
    },
  },
})

export const userSelector = (state: RootState): UserInfoType =>
  state.user.userInfo

export const currentImageIdSelector = (state: RootState): number =>
  state.user.currentImageId

// Action creators are generated for each case reducer function
export const { setUser, setCurrentImageId } = userReducer.actions
// You must export the reducer as follows for it to be able to be read by the store.
export default userReducer.reducer
