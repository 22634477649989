import React from "react"
import ReactDOM from "react-dom/client"
import { Toaster } from "react-hot-toast"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { SettingsProvider } from "./contexts/SettingsContext"
import "./locales/i18n"
import store from "./redux"
import Router from "./routes"
import ThemeProvider from "./theme"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SettingsProvider>
        <ThemeProvider>
          <BrowserRouter>
            <Toaster position="top-center" />
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </SettingsProvider>
    </Provider>
  </React.StrictMode>
)
