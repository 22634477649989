import { useTranslation } from "react-i18next"
import { enUS, faIR, Localization } from "@mui/material/locale"
import { ThemeDirection } from "../types/contexts"
import useSettings from "./useSettings"

export enum LangValues {
  Fa = "fa-IR",
  En = "en-UK",
}

export type LangSpec = {
  label: string
  value: LangValues
  systemValue: Localization
  direction: ThemeDirection
}

const LANGS: LangSpec[] = [
  {
    label: "English",
    value: LangValues.En,
    systemValue: enUS,
    direction: "ltr",
  },
  {
    label: "فارسی",
    value: LangValues.Fa,
    systemValue: faIR,
    direction: "rtl",
  },
]

type UseLocalesReturn = {
  onChangeLang: (lang?: string) => void
  currentLang: LangSpec
  allLang: LangSpec[]
}

export default function useLocales(): UseLocalesReturn {
  const { setDirection } = useSettings()

  const { i18n } = useTranslation()

  const currentLang =
    LANGS.find((_lang) => _lang.value === i18n.language) || LANGS[0]

  const handleChangeLanguage = (lang?: string) => {
    const language =
      lang === LangValues.Fa || lang === LangValues.En
        ? lang
        : i18n.language === LangValues.En
        ? LangValues.Fa
        : LangValues.En

    setDirection(
      language === LangValues.Fa ? LANGS[1].direction : LANGS[0].direction
    )
    i18n.changeLanguage(language)
  }

  return {
    onChangeLang: handleChangeLanguage,
    currentLang,
    allLang: LANGS,
  }
}
