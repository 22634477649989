function path(root: string, subLink: string) {
  return `${root}${subLink}`
}

const ROOTS_DASHBOARD = "/"

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  splash: path(ROOTS_DASHBOARD, "splash"),
  selectImage: path(ROOTS_DASHBOARD, "select-image"),
  improvement: path(ROOTS_DASHBOARD, "improvement"),
  boundaries: path(ROOTS_DASHBOARD, "boundaries"),
  treatments: path(ROOTS_DASHBOARD, "treatments"),
  analysis: path(ROOTS_DASHBOARD, "analysis"),
  login: path(ROOTS_DASHBOARD, "login"),
}
