/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, lazy, LazyExoticComponent } from "react"
import { Route, Routes } from "react-router-dom"
import MainLayout from "../layout/MainLayout"
import { NoAuth } from "./NoAuth"
import { RequireAuth } from "./RequireAuth"
import { PATH_DASHBOARD } from "./paths"

const Loadable = (Component: LazyExoticComponent<() => JSX.Element>) =>
  function load(props: any) {
    return (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    )
  }

const Splash = Loadable(lazy(() => import("../pages/Splash")))

const SelectImage = Loadable(lazy(() => import("../pages/SelectImage")))

const ImageImprovement = Loadable(
  lazy(() => import("../pages/ImageImprovement"))
)

const Boundaries = Loadable(lazy(() => import("../pages/Boundaries")))

const Treatments = Loadable(lazy(() => import("../pages/Treatments")))

const Analysis = Loadable(lazy(() => import("../pages/Analysis")))

const Login = Loadable(lazy(() => import("../pages/Login")))

export default function Router(): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> {
  return (
    <Routes>
      <Route element={<NoAuth />}>
        <Route path={PATH_DASHBOARD.root} element={<Login />} />
        <Route path={PATH_DASHBOARD.login} element={<Login />} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route element={<MainLayout />}>
          <Route path={PATH_DASHBOARD.splash} element={<Splash />} />
          <Route path={PATH_DASHBOARD.selectImage} element={<SelectImage />} />
          <Route
            path={PATH_DASHBOARD.improvement}
            element={<ImageImprovement />}
          />
          <Route path={PATH_DASHBOARD.boundaries} element={<Boundaries />} />
          <Route path={PATH_DASHBOARD.analysis} element={<Analysis />} />
          <Route path={PATH_DASHBOARD.treatments} element={<Treatments />} />
        </Route>
      </Route>
    </Routes>
  )
}
